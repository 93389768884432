.project__list {
  position: relative;
  max-width: 975px;
  margin-left: auto;
  // padding-right: 100px;

  &-item {
    width: 100%;
    padding: 3rem 0;
    padding-bottom: 1.5rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
    margin-bottom: 2rem;
    position: relative;
    z-index: 2;

    &::after {
      content: '';
      position: absolute;
      bottom: 0;
      width: 0%;
      height: 3px;
      display: block;
      background: var(--color-primary);
      transition: all 0.3s;
      right: 0;
      z-index: 1;
    }

    &::before {
      content: '';
      position: absolute;
      bottom: 0;
      width: 100%;
      height: 3px;
      display: block;
      background: rgba($color-primary, 0.16);

      transition: all 0.3s;
      left: 0;
      z-index: 0;
    }

    &:hover {
      z-index: 7 !important;
      position: relative;
    }
  }

  .project__title {
    font-size: clamp(2.5rem, 3vw, 5rem);
    font-weight: bold;
    color: var(--color-primary);
    -webkit-text-stroke: 1px var(--color-bg);

    @media only screen and (max-width: $mbl-land) {
      -webkit-text-stroke: 0.5px var(--color-bg);
    }
  }
  .project__tags {
    color: var(--color-primary);
    font-size: clamp(16px, 2vw, 2rem);
    mix-blend-mode: luminosity;
    font-weight: bold;
    -webkit-text-stroke: 0.3px var(--color-bg);
    @media only screen and (max-width: $mbl-land) {
      -webkit-text-stroke: 0.2px var(--color-bg);
    }
  }

  svg {
    width: 30px;
    height: 30px;
    fill: rgba($color-primary, 0.16);
    transform: rotate(45deg);
    transition: all 0.3s;
  }

  &-item:hover {
    &::after {
      right: inherit;
      left: 0;
      width: 100%;
    }

    svg {
      fill: rgba($color-primary, 1);
      transform: rotate(0deg);
    }
  }
}
