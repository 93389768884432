.skills {
	&__grid {
		// display: grid;

		// grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
		display: flex;
		align-items: flex-start;
		justify-content: space-between;
		flex-wrap: wrap;
		gap: 50px;
		&-item {
			min-width: max-content;

			h2 {
				white-space: nowrap;
			}
		}
	}
	&__list {
		display: flex;
		flex-direction: column;
		gap: 25px;

		h4 {
			font-size: clamp(2rem, 2vw, 3rem);
			white-space: nowrap;
			overflow: hidden;
			transform: translateY(-100px);
			opacity: 0;
		}
	}
}
