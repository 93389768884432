// ======================================
// utility classes
// ======================================

.text-center {
	text-align: center;
}
.text-right {
	text-align: right;
}
// ***************************************
.mx-auto {
	margin: 0 auto;
}
.ml-auto {
	margin-left: auto;
}
.mr-auto {
	margin-right: auto;
}
.m--1 {
	margin: 0.5rem;
}
.m-1 {
	margin: 1rem;
}
.m-2 {
	margin: 2rem;
}
.m-3 {
	margin: 3rem;
}
.m-3 {
	margin: 3rem;
}
.m-4 {
	margin: 4rem;
}
.m-5 {
	margin: 5rem;
}
// ***********************************

.p-1 {
	padding: 1rem;
}
.p-2 {
	padding: 2rem;
}
.p-3 {
	padding: 3rem;
}
.p-4 {
	padding: 4rem;
}
.p-5 {
	padding: 5rem;
}
// ***********************************
.mt-1 {
	margin-top: 1rem;
}
.mt-2 {
	margin-top: 2rem;
}
.mt-3 {
	margin-top: 3rem;
}
.mt-4 {
	margin-top: 4rem;
}
.mt-5 {
	margin-top: 5rem;
}
.mt-6 {
	margin-top: 6rem;
}
.mt-7 {
	margin-top: 7rem;
}
.mt-8 {
	margin-top: 8rem;
}
.mt-9 {
	margin-top: 9rem;
}
.mt-10 {
	margin-top: 10rem;
}
.mt-11 {
	margin-top: 11rem;
}
.mt-12 {
	margin-top: 12rem;
}
.mt-13 {
	margin-top: 13rem;
}
.mt-14 {
	margin-top: 14rem;
}
.mt-15 {
	margin-top: 15rem !important;
}
.mt-16 {
	margin-top: 16rem;
}
.mt-17 {
	margin-top: 17rem;
}
.mt-18 {
	margin-top: 18rem;
}
.mt-19 {
	margin-top: 19rem;
}
.mt-20 {
	margin-top: 20rem;
}
// ***********************************
.mb-1 {
	margin-bottom: 1rem;
}
.mb-2 {
	margin-bottom: 2rem;
}
.mb-3 {
	margin-bottom: 3rem;
}
.mb-4 {
	margin-bottom: 4rem;
}
.mb-5 {
	margin-bottom: 5rem;
}
.mb-6 {
	margin-bottom: 6rem;
}
.mb-7 {
	margin-bottom: 7rem;
}
.mb-8 {
	margin-bottom: 8rem;
}
.mb-9 {
	margin-bottom: 9rem;
}
.mb-10 {
	margin-bottom: 10rem;
}
.mb-11 {
	margin-bottom: 11rem;
}
.mb-12 {
	margin-bottom: 12rem;
}
.mb-13 {
	margin-bottom: 13rem;
}
.mb-14 {
	margin-bottom: 14rem;
}
.mb-15 {
	margin-bottom: 15rem;
}
.mb-16 {
	margin-bottom: 16rem;
}
.mb-17 {
	margin-bottom: 17rem;
}
.mb-18 {
	margin-bottom: 18rem;
}
.mb-19 {
	margin-bottom: 19rem;
}
.mb-20 {
	margin-bottom: 20rem;
}
// ***********************************
.pt-1 {
	padding-top: 1rem;
}
.pt-2 {
	padding-top: 2rem;
}
.pt-3 {
	padding-top: 3rem;
}
.pt-4 {
	padding-top: 4rem;
}
.pt-5 {
	padding-top: 5rem;
}
.pt-6 {
	padding-top: 6rem;
}
.pt-7 {
	padding-top: 7rem;
}
.pt-8 {
	padding-top: 8rem;
}
.pt-9 {
	padding-top: 9rem;
}
.pt-10 {
	padding-top: 10rem;
}
.pt-11 {
	padding-top: 11rem;
}
.pt-12 {
	padding-top: 12rem;
}
.pt-13 {
	padding-top: 13rem;
}
.pt-14 {
	padding-top: 14rem;
}
.pt-15 {
	padding-top: 15rem;
}
.pt-16 {
	padding-top: 16rem;
}
.pt-17 {
	padding-top: 17rem;
}
.pt-18 {
	padding-top: 18rem;
}
.pt-19 {
	padding-top: 19rem;
}
.pt-20 {
	padding-top: 20rem;
}
// ***********************************
.pb-1 {
	padding-bottom: 1rem;
}
.pb-2 {
	padding-bottom: 2rem;
}
.pb-3 {
	padding-bottom: 3rem;
}
.pb-4 {
	padding-bottom: 4rem;
}
.pb-5 {
	padding-bottom: 5rem;
}
.pb-6 {
	padding-bottom: 6rem;
}
.pb-7 {
	padding-bottom: 7rem;
}
.pb-8 {
	padding-bottom: 8rem;
}
.pb-9 {
	padding-bottom: 9rem;
}
.pb-10 {
	padding-bottom: 10rem;
}
.pb-11 {
	padding-bottom: 11rem;
}
.pb-12 {
	padding-bottom: 12rem;
}
.pb-13 {
	padding-bottom: 13rem;
}
.pb-14 {
	padding-bottom: 14rem;
}
.pb-15 {
	padding-bottom: 15rem;
}
.pb-16 {
	padding-bottom: 16rem;
}
.pb-17 {
	padding-bottom: 17rem;
}
.pb-18 {
	padding-bottom: 18rem;
}
.pb-19 {
	padding-bottom: 19rem;
}
.pb-20 {
	padding-bottom: 20rem;
}
// ***********************************
.block {
	display: block;
}
.inline-block {
	display: inline-block;
}
.flex {
	display: flex;
}
.align-center {
	align-items: center;
}
.justify-center {
	justify-content: center;
}
.justify-between {
	justify-content: space-between;
}
.flex-column {
	flex-direction: column;
}

// ***************************************
.ch-30 {
	max-width: 30ch;
}
