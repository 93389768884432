.nav {
	position: absolute;
	left: 0;
	width: 100%;
	z-index: 9;
	padding-bottom: clamp(20px, 4vw, 4rem);
	top: clamp(20px, 4vw, 4rem);
	z-index: 9;
	opacity: 1;
	transform: translateY(-100%);
	transition: all 0.3s;

	&.on-screen {
		transition-delay: 0.3s;
		transform: translateY(0);
	}

	&__container {
		display: flex;
		align-items: center;
		justify-content: space-between;
	}

	// .about__link {
	// 	font-size: clamp(2rem, 2vw, 3.5rem);
	// 	font-weight: 700;
	// 	color: var(--color-primary);
	// 	-webkit-text-stroke: 0.5px var(--color-bg);
	// }

	.nav__menu {
		display: flex;
		align-items: center;
		gap: 3rem;
		&-link {
			font-size: clamp(2rem, 2vw, 3.5rem);
			font-weight: 700;
			color: var(--color-primary);
			-webkit-text-stroke: 0.5px var(--color-bg);
			transition: all 0.3s;
			display: flex;
			align-items: center;
			line-height: 1;

			img,
			svg {
				height: clamp(2rem, 2vw, 3.5rem);
				margin-left: 5px;
			}
			&:hover,
			&.active {
				color: transparent;
				-webkit-text-stroke: 1px var(--color-primary);
			}
		}
	}
}
