$white: #fff;

.cursor {
	pointer-events: none;
	width: 40px;
	height: 40px;
	border: 2px solid $white;
	border-radius: 50%;
	display: block;
	position: fixed;
	transition: all 0.05s ease;
	transform: translate(-15px, -12px);
	mix-blend-mode: difference;
	z-index: 50;

	&::after {
		content: '';
		position: absolute;
		top: 50%;
		left: 50%;
		width: 100%;
		height: 100%;
		border-radius: 50%;
		display: flex;
		justify-content: center;
		align-items: center;
		background: var(--color-primary);
		color: var(--color-bg);
		transition: all 0.5s ease;
		transform: translate(-50%, -50%) scale(0);
		transform-origin: center;
		// mix-blend-mode: difference;
	}

	&.project-view {
		// width: 15rem;
		// height: 15rem;
		mix-blend-mode: normal;
		border-color: transparent;
		&::after {
			content: 'View';
			opacity: 1;
			transition: all 0.5s ease;
			transform: translate(-50%, -50%) scale(2);
			padding: 2rem;
			@media only screen and (max-width: 768px) {
				transform: translate(-50%, -50%) scale(1.5);
			}
		}
	}

	&.logo,
	&.button {
		// mix-blend-mode: normal;
		border-color: transparent;
		&::after {
			opacity: 1;
			transform: translate(-50%, -50%) scale(2);
			mix-blend-mode: difference;
			background: $white;
			border: 2px solid $white;
			padding: 2rem;
			@media only screen and (max-width: 768px) {
				transform: translate(-50%, -50%) scale(1.5);
			}
		}
	}

	&.logo {
		&::after {
			transform: translate(-50%, -50%) scale(1.5);
			padding: 2rem;
			@media only screen and (max-width: 768px) {
				transform: translate(-50%, -50%) scale(1.2);
			}
		}
	}
}

.cursor {
	&.project-list {
		mix-blend-mode: normal;
		z-index: 0;
		.cursor__inner {
			width: 300px;
			height: 400px;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
			pointer-events: none;
			transform-origin: center center;
			background-size: cover;
			background-position: 50% 50%;
			mix-blend-mode: normal;
			position: relative;
			z-index: 0 !important;

			@media only screen and (max-width: 575px) {
				width: 150px;
				height: 200px;
			}
		}
	}
}
