.social {
	max-width: max-content;
	display: flex;
	align-items: center;
	gap: 3rem;

	@media only screen and (max-width: $tab-port) {
		margin: 0 auto;
		margin-bottom: 2rem;
	}
	&__links {
		display: flex;
		align-items: center;
		gap: 2rem;
		a {
			width: clamp(3rem, 3vw, 4rem);
			height: clamp(3rem, 3vw, 4rem);
			border-radius: 50%;
			transition: all 0.3s;
			display: flex;
			justify-content: center;
			align-items: center;
			padding: 5px;
			svg {
				transition: all 0.3s;
				width: clamp(2rem, 2vw, 2.5rem);
				height: clamp(2rem, 2vw, 2.5rem);
				fill: var(--color-bg);
			}

			&:hover {
				background: var(--color-bg);

				svg {
					fill: var(--color-primary);
				}
			}
		}
	}
}
