.loader {
	position: fixed;
	left: 0;
	bottom: 0;
	width: 100%;
	height: 100vh;
	z-index: 30;
	pointer-events: none;
	transition: all 0.3s;
	overflow: hidden;
	background: var(--color-bg);
	// padding: ;
	.left,
	.right {
		width: 100%;
		position: absolute;
		z-index: 30;
		background-color: var(--color-primary);
		transition: all 0.3s;
		height: 50vh;
	}

	.left {
		left: 0;
		top: 0;
	}

	.right {
		right: 0;
		bottom: 0;
	}
	.message {
		position: absolute;
		top: 50%;
		left: 50%;
		min-width: max-content;
		text-align: center;
		margin: 0 auto;
		padding: 0 20px;
		transform: translate(-50%, -50%);
		color: var(--color-bg);
		font-size: 10vw;
		z-index: 31;
		text-align: center;
		mix-blend-mode: difference;
	}
}
