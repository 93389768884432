.header {
	width: 100%;
	position: relative;
	min-height: 100vh;
	padding-bottom: 100px 0;
	display: flex;
	align-items: center;

	.not__found,
	.header__content {
		padding-top: 13vh;
	}

	.case__banner {
		position: relative;
		margin-top: 100px;
		margin-bottom: 50px;

		&-img {
			max-height: 560px;
			max-width: 945px;
			margin: 0 auto;
			width: 100%;
			height: 100%;
			object-fit: cover;
			box-shadow: 0 0 20px rgba($color-primary, 0.16);
		}
		&-title {
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
			font-size: clamp(5rem, 11.5vw, 220px);
			width: 100%;
			line-height: 1;
			display: block;
			overflow: hidden;
			padding: 10px 0;

			mix-blend-mode: luminosity;
			color: var(--color-bg);
			-webkit-text-stroke: 3px var(--color-primary);
			@media only screen and (max-width: $tab-port) {
				-webkit-text-stroke: 2px var(--color-primary);
				font-size: 15vw;
			}
		}
	}

	@media only screen and (max-width: $tab-port) {
		min-height: 60vh;
	}
}
