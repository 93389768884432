.btn {
	&,
	&:link,
	&:visited {
		font-size: 2.5rem;
		font-weight: 600;
		display: inline-block;
		display: flex;
		justify-content: center;
		align-items: center;
		border-radius: 50%;
		margin: 0 auto;
		margin-top: 10rem;
		margin-bottom: 5rem;
		transition: all 0.3s;
	}

	&__primary {
		width: 15rem;
		height: 15rem;
		color: var(--color-bg);
		background: var(--color-primary);
		position: relative;
		z-index: 2;
		box-shadow: 0 0 50px rgba($color-primary, 0.16);
		overflow: hidden;

		&::after {
			content: '';
			position: absolute;
			bottom: 0;
			left: 0;
			width: 100%;
			height: 100%;
			border-radius: 50%;
			transform: scaleX(1.2) scaleY(0);
			transform-origin: bottom;
			transition: all 0.3s;
			background: var(--color-bg);
			box-shadow: 0 0 50px rgba($color-primary, 0.16);
			z-index: -1;
		}

		&:hover {
			color: var(--color-primary);
			background: var(--color-bg);
			&::after {
				transform: scaleX(1.2) scaleY(1.2);
			}
		}

		@media only screen and (max-width: $tab-port) {
			width: 12rem;
			height: 12rem;
			font-size: 2rem !important;
			padding: 5px;
		}
	}

	&__cta {
		background: var(--color-bg);
		color: var(--color-primary);
		width: 13rem;
		height: 13rem;
		border: 2px solid transparent;
		// box-shadow: 0 0 50px rgba($color-secondary, 0.5);
		position: relative;
		z-index: 2;
		overflow: hidden;

		&::after {
			content: '';
			position: absolute;
			bottom: 0;
			left: 0;
			width: 100%;
			height: 100%;
			border-radius: 50%;
			transform: scaleX(1.2) scaleY(0);
			transform-origin: bottom;
			transition: all 0.3s;
			background: var(--color-primary);
			z-index: -1;
		}

		&:hover {
			color: var(--color-bg);
			border-color: currentColor;
			// box-shadow: 0 0 50px rgba($color-secondary, 0.1);
			&::after {
				transform: scaleX(1.2) scaleY(1.2);
			}
		}
		@media only screen and (max-width: $tab-port) {
			width: 10rem;
			height: 10rem;
			font-size: 1.8rem !important;
			padding: 5px;
		}
	}

	&.not-found {
		margin-top: 5rem;
	}
}
