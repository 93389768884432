* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  text-decoration: none;
  list-style: none;
  outline: none;
}

html {
  font-size: 62.5%;
  scroll-behavior: smooth;
  overflow-x: hidden;
}

::selection {
  background: var(--color-primary);
  color: var(--color-bg);
}

body {
  overflow-x: hidden;
  background: var(--color-bg);
  font-size: 1.8rem;
  font-family: var(--font-primary);
  line-height: 1.4;
  color: var(--color-primary);

  &,
  & > * {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
    &::-webkit-scrollbar {
      display: none;
    }
  }
}

main {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  &::-webkit-scrollbar {
    display: none;
  }
}

header,
footer,
section {
  padding: 10rem 0;
  position: relative;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  &::-webkit-scrollbar {
    display: none;
  }

  @media only screen and (max-width: $laptop-land) {
    padding: 5rem 0;
  }
}

.container {
  width: 90%;
  max-width: 1640px;
  margin: 0 auto;
  overflow: hidden;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  &::-webkit-scrollbar {
    display: none;
  }

  &__small {
    max-width: 1340px;
  }
}

.logo {
  align-self: flex-start;
  max-width: clamp(60px, 6vw, 100px);
  display: inline-block;
  mix-blend-mode: difference;
  transform-origin: center center;
}

img {
  max-width: 100%;
}

.shadow {
  filter: drop-shadow(0 0 50px rgba($color-primary, 0.16));
}

.link {
  color: inherit;
}

// ========================================
// ========================================
.my__image {
  --my-image: url(https://avatars.githubusercontent.com/u/57148171?v=4);
  width: calc(250px + 10vw);
  height: calc(250px + 10vw);
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: -1;
  filter: grayscale(1);
  &::before {
    position: absolute;
    content: '';
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: var(--my-image);
    background-size: cover;
    background-position: top center;
    border-radius: 62% 47% 82% 35% / 45% 45% 80% 66%;
    will-change: border-radius, transform, opacity;
    animation: changeShape 5s linear infinite;
    display: block;
    z-index: -1;
    -webkit-animation: changeShape 5s linear infinite;
  }
}
@keyframes changeShape {
  0%,
  100% {
    border-radius: 42% 58% 70% 30% / 45% 45% 55% 55%;
    transform: translate3d(0, 0, 0) rotateZ(0.01deg);
  }
  34% {
    border-radius: 70% 30% 46% 54% / 30% 29% 71% 70%;
    transform: translate3d(0, 5px, 0) rotateZ(0.01deg);
  }
  50% {
    transform: translate3d(0, 0, 0) rotateZ(0.01deg);
  }
  67% {
    border-radius: 100% 60% 60% 100% / 100% 100% 60% 60%;
    transform: translate3d(0, -3px, 0) rotateZ(0.01deg);
  }
}
