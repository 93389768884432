.footer {
	background: var(--color-primary);
	padding-bottom: 0;

	.container {
		border-bottom: 1px solid rgba($color-bg, 0.16);
	}

	.btn__cta {
		margin-bottom: 10rem;
	}

	.copyright {
		padding: 2rem 1rem;
		text-align: center;
		color: var(--color-bg);
		opacity: 0.7;
	}
}
