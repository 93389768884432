.featured {
  position: relative;

  .container {
    &__small {
      width: 100%;
      max-width: none;
    }
  }
  &__projects {
    position: relative;
    display: flex;
    align-items: center;

    &-item {
      flex: 0 0 100%;
      height: 95vh;
      margin: 8rem 0;
      display: flex;
      align-items: center;
      justify-content: center;
      overflow: hidden;

      .item__info {
        transform: translateX(calc(150px / 2));
        z-index: 1;
        .project__name {
          max-width: max-content;
          font-size: 2.5rem;
          font-weight: 600;
          mix-blend-mode: luminosity;
          background: var(--color-bg);
          color: var(--color-primary);
        }
        .project__title {
          font-size: clamp(4rem, 4vw, 6rem);
          background: var(--color-bg);
          padding: 2rem 0;
          line-height: 1.2;
          padding-right: 2rem;
          // letter-spacing: 10px;
        }
        .project__tags {
          max-width: max-content;
          background: var(--color-bg);
        }
      }
      .item__preview {
        flex: 0 0 40%;
        height: 100vh;
        transform: translateX(calc(-150px / 2));

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }

      @media only screen and (max-width: 1300px) {
        justify-content: center;
        .item__info {
          // transform: translateX(150px);
          flex: 0 0 50%;
        }
        .item__preview {
          flex: 0 0 50%;
        }
      }
      @media only screen and (max-width: $laptop-land) {
        .item__info {
          transform: translateX(0);
          flex: 0 0 50%;
        }
        .item__preview {
          flex: 0 0 50%;
          // transform: translateX(-100px);
          height: 90vh;
          img {
            width: 100%;
            height: 100%;
          }
        }
      }
      @media only screen and (max-width: $tab-land) {
        .item__info {
          transform: translateX(100px);
          flex: 0 0 60%;
        }
        .item__preview {
          flex: 0 0 60%;
          // transform: translateX(-100px);
          height: 90vh;
          img {
            width: 100%;
            height: 100%;
          }
        }
      }
      @media only screen and (max-width: $tab-port) {
        flex-wrap: wrap;
        .item__info {
          transform: translateX(0);
          flex: 0 0 100%;
          order: 2;

          .project__name {
            margin-bottom: 0;
            padding: 0;
            font-size: 2rem;
          }
          .project__title {
            padding: 8px 0;
            font-size: 3rem;
          }
        }
        .item__preview {
          flex: 0 0 100%;
          transform: translateX(0);
          order: 1;
          max-height: 300px;
          border-radius: 15px;
          overflow: hidden;
          margin-bottom: 3rem;
        }
      }
    }

    @media only screen and (max-width: $laptop-port) {
      flex-direction: column;
      &-item {
        height: auto;
        margin: 4rem 0;
      }
    }
  }
}
