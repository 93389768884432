.case__images {
  position: relative;
  padding-top: 0;

  .info__image {
    display: grid;
    grid-template-columns: 2fr 1.2fr;
    align-items: center;
    gap: 5rem;
    &-img {
      overflow: hidden;
      img {
        border-radius: 2rem;
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    &-info {
      display: flex;
      flex-direction: column;
      gap: 1rem;

      a,
      h4 {
        font-size: clamp(2.5rem, 2vw, 4rem);
        font-weight: 600;
        color: var(--color-primary);
      }

      a.live {
        border-bottom: 2px solid var(--color-primary);
        max-width: max-content;
        line-height: 1;
      }
    }

    @media only screen and (max-width: $tab-port) {
      display: flex;
      flex-direction: column-reverse;
      align-items: flex-start;
    }
  }

  .phone__image {
    padding: 10rem 0;
    max-width: 90rem;
    margin: 0 auto;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    gap: 5rem;
    &-img {
      text-align: center;
      max-height: 72rem;
      img {
        height: 100%;
        object-fit: contain;
      }
      &--2 {
        margin-top: 35rem;
      }
    }
    @media only screen and (max-width: 700px) {
      padding: 0;
      &-img {
        &--2 {
          margin-top: 0;
        }
      }
    }
  }
}
