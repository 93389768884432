h1.impact,
h2.impact {
	font-family: var(--font-secondary);
	text-transform: uppercase;
	font-size: 18vw;
	white-space: nowrap;
	font-weight: bold;
	position: relative;
	.line {
		line-height: 1;
		overflow: hidden;

		span {
			display: block;
		}
	}
	.outline__text {
		color: transparent;
		-webkit-text-stroke: 3px var(--color-primary);

		@media only screen and (max-width: $tab-land) {
			-webkit-text-stroke: 2px var(--color-primary);
		}
		@media only screen and (max-width: $tab-port) {
			-webkit-text-stroke: 1.5px var(--color-primary);
		}
		@media only screen and (max-width: $mbl-land) {
			-webkit-text-stroke: 1px var(--color-primary);
		}
		@media only screen and (max-width: $mbl-port) {
			-webkit-text-stroke: 0.8px var(--color-primary);
		}
	}

	&.about__heading {
		font-size: clamp(8rem, 12vw, 280px);
		position: relative;

		span {
			padding: 3px 0;
		}
		& > * {
			line-height: 0.8;
		}

		@media only screen and (max-width: $mbl-land) {
			font-size: 15vw;
			white-space: normal;
		}
	}

	&.name {
		font-size: 17vw;
		position: relative;
		span {
			display: block;
			max-width: max-content;
			position: relative;
			z-index: 2;
			color: rgba($color-bg, 0.2);
		}
		@media only screen and (max-width: $mbl-land) {
			font-size: 22vw;
			white-space: normal;
		}
	}

	&.home__intro {
		font-size: clamp(5rem, 9vw, 180px);
		span {
			display: block;
			padding: 3px 0;
			letter-spacing: 4px;
		}
		& > * {
			line-height: 0.8;
		}

		@media only screen and (max-width: $mbl-land) {
			font-size: 16vw;
			white-space: normal;
			letter-spacing: 0px;
		}
	}

	&.not-found {
		font-size: 10vw;
		@media only screen and (max-width: $tab-land) {
			font-size: 16vw;
		}
		@media only screen and (max-width: $mbl-land) {
			font-size: 20vw;
		}
	}
}

h2.heading {
	font-size: clamp(2.5rem, 3vw, 6rem);
}

.animated__headline {
	margin-top: 10rem;
	.m-scroll {
		display: flex;
		position: relative;
		width: 100%;
		min-height: 300px;
		margin: auto;
		overflow: hidden;
		z-index: 1;

		&__title {
			display: flex;
			position: absolute;
			top: 50%;
			left: 0;
			align-items: center;
			justify-content: flex-start;
			width: 100%;
			height: 100%;
			white-space: nowrap;
			transform: translateY(-40%) scale(2);
			transition: all 1s ease;

			& > div {
				display: flex;
				animation: scrollText 33s infinite linear;
				// gap: 50px;
			}
			h2 {
				margin: 0;
				font-size: clamp(4rem, 8vw, 180px);
				color: transparent;
				transition: all 2s ease;
				text-transform: uppercase;
				-webkit-text-stroke: 2px var(--color-primary);

				@media only screen and (max-width: $mbl-land) {
					-webkit-text-stroke: 1.5px var(--color-primary);
				}
			}
		}

		@media only screen and (max-width: $laptop-port) {
			height: auto;
			padding: 10rem 0;
		}
		@media only screen and (max-width: $tab-port) {
			min-height: 100px;
			padding-bottom: 0;
			margin-top: 5rem;
			&__title {
				height: 50%;
				padding-bottom: 0;
			}
		}
	}

	/*div:hover {
  animation-play-state: paused;
}*/
	@keyframes scrollText {
		from {
			transform: translateX(0%);
		}
		to {
			transform: translateX(-50%);
		}
	}
}

h2.section__heading {
	font-size: clamp(3.5rem, 6vw, 10rem);
	display: flex;
	align-items: center;
	width: min-content;
	position: relative;
	gap: 20px;

	&::after {
		content: '';
		position: absolute;
		top: 50%;
		left: calc(100% + 30px);
		width: 5000px;
		height: 2px;
		background: var(--color-primary);
	}

	.outline__text {
		color: transparent;
		-webkit-text-stroke: 1px var(--color-primary);
	}

	.sub__heading {
		font-size: clamp(1.6rem, 2vw, 2.5rem);
		font-weight: 600;
		white-space: nowrap;
	}

	&-small {
		font-size: clamp(2.5rem, 2.5vw, 5rem);
		max-width: max-content;
		position: relative;
		border-bottom: 3px solid rgba($color-primary, 1);
		line-height: 1.1;

		&::after {
			display: none;
		}
	}

	@media only screen and (max-width: $tab-port) {
		// flex-wrap: wrap;
		.sub__heading {
			white-space: normal;
			flex: 0 0 100%;
			width: 100%;
			position: absolute;
			bottom: -30px;
			left: 0;
			width: 100%;
			min-width: 300px;
		}
	}
}

h3.heading {
	font-size: clamp(3rem, 3vw, 5rem);
	font-weight: 600;

	&.current-status {
		a {
			color: inherit;
			border-bottom: 3px solid currentColor;
		}
	}
	&.ml-auto {
		max-width: max-content;
	}
}

h2.footer__heading {
	font-size: clamp(3rem, 4vw, 8rem);
	color: transparent;
	-webkit-text-stroke: 2px var(--color-bg);

	@media only screen and (max-width: $mbl-land) {
		-webkit-text-stroke: 1.5px var(--color-bg);
	}
}
h3.footer__heading {
	font-size: clamp(2.5rem, 4vw, 6rem);
	color: var(--color-bg);
}

h4.heading {
	font-size: clamp(2.5rem, 2.5vw, 4rem);
	line-height: 1;
}
.about__text {
	font-size: clamp(2.5rem, 2.5vw, 4rem);
	max-width: 58ch;
	opacity: 0;
	transform: translateY(200px);
	transition: all 1s;
	transition-delay: 0.5s;
	letter-spacing: -1px;

	&.is-inview {
		opacity: 1;
		transform: translateY(0);
	}
}

.text__secondary {
	color: var(--color-bg);
}

.outline__text {
	&,
	& div {
		background: linear-gradient(90deg, transparent, transparent 50%, $color-primary 50%);
		background-size: 200%;
		transition: background 0.5s cubic-bezier(1, 0.01, 0.13, 1);
		background-clip: text;
	}

	&:hover,
	&:hover div {
		background-position: 100%;
	}
}

.nameIntro {
	margin-left: clamp(50px, 2vw, 10rem);
}
